import Banner from "../components/Banner";
import Proposals from "../components/Proposals";

function Home() {
  return (
    <>
      <Banner />
      <Proposals />
    </>
  );
}

export default Home;
