import ProposalDetails from "../components/ProposalDetails";
import Voters from "../components/Voters";

function Proposal() {
  return (
    <>
      <ProposalDetails />
      <Voters />
    </>
  );
}

export default Proposal;
